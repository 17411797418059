










































import { Component, Vue } from 'vue-property-decorator'
import globalMixins from '@/mixins/global.ts'
import fileUpload from '@/components/common/fileUpload.vue'
import { Getter, Action } from 'vuex-class'

@Component({
  mixins: [globalMixins],
  components: {
    fileUpload
  }
})
export default class textImport extends Vue<globalMixins> {
  @Action('doImport') doImport: Function
  @Action('downloadImpTemp') downloadImpTemp: Function
  @Getter('downloadImpTempUrl') downloadImpTempUrl: Function
  labelPosition: string = 'right'
  detectData: any[] = []
  errorInfo: string = ''
  tip: boolean = true
  importAbled: boolean = false
  importBtnabled: boolean = true
  dialogVisible: boolean = false
  importTemAbled: boolean = false
  project: any = JSON.parse(localStorage.getItem('projects'))
  imgDisplay (url, data) {
    this.detectData = data
    if (data.length) {
      this.importBtnabled = false
    }
  }
  onSubmit () {
    this.importAbled = true
    let params = {
      'file_id': this.detectData[0].id,
      projectId: this.project.id
    }
    this.doImport({ params }).then(() => {
      this.successMsg('导入病例成功！')
      this.importAbled = false
    }).catch((err) => {
      this.errorInfo = err.response.data.error.message
      this.dialogVisible = true
      this.importAbled = false
    })
  }
  download () {
    this.importTemAbled = true
    let params = {
      projectId: this.project.id
    }
    this.downloadImpTemp({ params }).then(() => {
      console.log('download-import-url', this.downloadImpTempUrl)
      let tempwindow: any = window.open('_blank')
      tempwindow.location = this.downloadImpTempUrl
      this.successMsg('下载模板成功！')
      this.importTemAbled = false
    }).catch((err: any) => {
      this.importTemAbled = false
      this.errorInfo = err.response.data.error.message
    })
  }
  imgDelete () {
    this.importBtnabled = true
  }
}
