


























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import globalMixins from '@/mixins/global.ts'

@Component({
  mixins: [globalMixins]
})
export default class ATSComponentsFileUpload extends Vue<globalMixins> {
  @Prop()
  type: string
  @Prop() 
  limitType: string
  @Prop() 
  btnDisabled: boolean

  api: string = ''
  name: string = ''
  imgUrl: string = ''
  uploadApi: string = ''
  dialogImageUrl: string = ''
  dialogVisible: boolean = false
  diffType: string = ''
  project: object = JSON.parse(localStorage.getItem("projects"))
  created () {
    if (process.env.NODE_ENV === 'production') {
      let domation = window.location.host
      this.api = `https://${domation}/api`
    } else {
      this.api = 'https://test.myathena.cn/api'
    }
    this.uploadApi = this.limitType === 'text' ? this.api + '/files' : this.api + `/projects/${(this.project as any).id}/cases/detect-text/`
  }
  get acceptType () {
    if (this.limitType === 'text') {
      this.diffType = 'xls / xlsx'
      this.name = 'files'
      return '.xls, .xlsx'
    } else {
      this.diffType = 'jpg / jpeg / png'
      this.name = 'file'
      return 'image/jpg, image/jpeg, image/png'
    }
  }
  get headers () {
    const Bearer = 'Bearer '
    return {Authorization: (Bearer + localStorage.getItem('token'))}
  }
  clearFiles () {
    (this.$refs.uploador as any).clearFiles()
  }
  handleError (err, file, fileList) {
    this.errorMsg(err.error.message)
  }
  handleExceed (files, fileList) {
    this.warnMsg('请删除已经存在的文件后再操作!')
  }
  handlePreview (file) {
    this.dialogImageUrl = URL.createObjectURL(file.raw)
    this.dialogVisible = true
  }
  handleRemove () {
    this.$emit('imgDelete')
  }
  handleSuccess (response, file, fileList) {
    this.imgUrl = URL.createObjectURL(file.raw)
    this.$emit('imgDisplay', this.imgUrl, response.data)
  }
  @Watch('type')
  ontypechange(val: string, old: string) {
    if (val === 'image') {
      this.uploadApi = this.api + `/projects/${(this.project as any).id}/cases/detect-text/`
    } else if (val === 'field') {
      this.uploadApi = this.api + `/projects/${(this.project as any).id}/cases/detect/`
    }
  }
}
